.headerContainer {
    border-radius: 10px;
    // border: 1px solid #FFF;
    background: #1A1D2A;
    backdrop-filter: blur(2px);
    padding: 2rem;
    padding-left: 20vw;
    padding-right: 20vw;

    .logo {
        height: 6rem;
    }
}

