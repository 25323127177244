.carousel {
    display: flex;
    gap: 1.25rem;
    overflow-x: scroll;
    // justify-content: center;
    // max-width: 59rem;
    display : -webkit-flex;
    // width: 100%;
    &::-webkit-scrollbar {
        display: none;
      }
      
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    div {
        flex-shrink: 0;
    }
}