.form{
    font-size:1.125rem;
    font-weight: 700;
    
}

.fulfilment{
    padding: 1rem 1rem 1.25rem 1.25rem;
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(5px);

}

.question-form{
    padding-top: 15px;
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;

}

.investList{
    position: absolute;
    right: 0;
    z-index: 1000;
    background: #3D4553;
    max-width:120px;
    padding-right: 10px;
    border-radius: 8px;

}

.invest-options{
    padding-top: 0.3rem;
    padding-bottom: 0.7rem;
    padding-left: 0.9rem;
    padding-right: 0.1rem;
    bottom: 0.35rem;

    cursor: pointer;

}

.fulfil{
    position: relative;

}

.productList{
    position: absolute;
    right: 0;
    z-index: 1000;
    background: #3D4553;
    max-width:120px;
    border-radius: 8px;

}

.product-question{
    padding-bottom: 0.5rem;

}

.meeting-question{
    padding-bottom: 0.5rem;

}

.invest-question{
    padding-bottom: 0.5rem;

}

.type-fulfilment{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

}

.purpose{
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

}

.date{
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

}
select{
    width: 100%;
    background:transparent;
    border:none;
    border-bottom: 2px solid rgba(245, 245, 250, 0.40);
    color: white;
    outline: none;

}

select.type-fulfilment option{
    background: #3D4553;
    color: white;

}

.expert{
    padding-top: 10px;
    display: flex;
    justify-content: right;

}


.react-datepicker-wrapper{
    width: 100%;

}
.react-datepicker__close-icon {
    display: none !important;

}

.question-secondform{
    // padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;

}
.dropdown-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%);
}

.customdate{
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;

}

.customcheck{
    height: 1.1rem;
    width: 1rem;
    margin-right: 0.625rem;

}

.meetings{
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    
}

.custom-header{
    padding-top: 1rem;
    padding-bottom: 1rem;

}

.position-form{
    position: relative;

}

.invest-options-product{
    position: relative;
    // padding-bottom: 1rem;
    margin-bottom: 1rem;

}

.invest-dropdown{
    position: absolute; 
    bottom: 15px; 
    right: 0px;

}

.date-align{
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;

}

.send-tick{
    // width: 1rem;
    height: 1.3rem;
    right: 0;
    position: absolute;

}

.date-tick {
    bottom: 1.25rem;
}

.custom-input{
    padding-right: 25px;
    
}