// @tailwind base;
@tailwind components;
@tailwind utilities;

.emoji-button{
  border:none;
  background-color: transparent;
}

.first-button {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.last-button {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
.newWidth {
  width: 6rem;
}

@media screen and (max-width: 1500px) {
  .newWidth {
    width: 5rem;
  }
}
@media screen and (max-width: 1275px) {
  .newWidth {
    width: 4rem;
  }
}
@media screen and (max-width: 1075px) {
  .newWidth {
    width: 3rem;
  }
}
@media screen and (max-width: 843px) {
  .newWidth {
    width: 2rem;
  }
}
.emoji-container {
  background: transparent;
  padding: .2rem;
  // margin: 0;
  width: 100%;
}
