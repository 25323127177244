.Sentiment{

    // width: 100%;
    // height: 9.6875rem;
    // border-radius: 0.625rem;
    padding: 1rem;
background: rgba(10, 14, 23, 0.60);
border-radius: 0.625rem;
}

.sentimentGraph {
    padding: 1rem;
}


.sentimentHead{
    display:flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.dataSentiment{
    display: flex;
    justify-content: start;
}

.headingtext{
    color: #F8F8F8;
    font-feature-settings: 'clig' off, 'liga' off;
    
    /* headline */
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 10px;
}

.sentEmote{
    padding: 0 10px;
}

.pin{
    padding-left: 10px;
}

.emoteStatus{
    color: #F8F8F8;
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;

/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-left: 10px;
padding-right: 20px;
}

.newWork{
    display: flex;
    justify-content: start;
}