@import '../../common/styles/fonts.scss';

.event {
    padding: 0.5rem;
    border: 1.15px solid rgba(108, 132, 189, 0.4);
    // border-image-source: 
    // linear-gradient(193.44deg, rgba(255, 255, 255, 0.4) 2.36%, rgba(108, 132, 189, 0.4) 100%);

    width: 27.75rem;
    border-radius: 0.625rem;
    background: rgba(248, 248, 248, 0.15);
    backdrop-filter: blur(2px);

    .display {
        width: 100%;
        // height: 14.9375rem;
        height: 14.69rem;
        position: relative;
        border-radius: 0.25163rem;
        cursor:pointer;

        .title {
            color: #f8f8f8;
            @include font-18-bold-header;
        }

        .subTitle1 {
            color: rgba(248, 248, 248, 0.50);
            @include font-14-semibold-subheading;
        }

        .subTitle2 {
            @extend .subTitle1;
            font-weight: 400;
        }


        .thumbnail {
            &>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.25163rem;

                // object-fit: contain;
            }

            position: absolute;
            top: 0;
            left: 0;
            // max-height: 40px;
            // max-width: 40px;
            width: 100%;
            height: 100%;
            border-radius: 0.25163rem;
            // object-fit: cover;
        }

        .overlay {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            border-radius: 0.25163rem;
            background: linear-gradient(180deg, rgba(9, 13, 22, 0.90) 0%, rgba(10, 17, 33, 0.90) 100%);
        }

        .eventDetails {
            position: absolute;
            width: 100%;
            // text-align: center;
            // justify-content: center;
            // height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .badge {
            position: absolute;
            top: 0.62rem;
            left: 0.37rem;
            z-index: 2;
        }

        .hostPic {
            // max-width: 42px;
            margin-right: 7.9px;
            border-radius: 50%;
            max-height: 3.5rem;
            width: 5.7rem;
            object-fit: contain;
            // & > img {
            //     object-fit: cover;
            // }
        }
    }

    .footer {
        padding: 0.5rem;

        &Headings{
            padding: 0.5rem;
            padding-bottom: 1.81rem;
        }

        &Title {
            @include font-18-bold-header;
            color: #F8F8F8;
        }

        &SubTitle {
            @include font-14-semibold-subheading;
            color: rgba(248, 248, 248, 0.50);
        }

        &Timestamp {
            @include font-12-regular-caption;
            color: var(--light-grey, #E4E6EA);
            text-align: right;
        }
    }
}