.noScreen{
    width: 100%;
    border-radius: 0.625rem;
background: rgba(248, 248, 248, 0.15);
color: white;
padding-left: 1.38rem;
padding-right: 1.38rem;
border: 1px solid grey;

}

.heading-noscreen{
    display: flex;
    flex-direction: start;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.noChange{
    display:flex;
    flex-direction: column;
}
.upcoming{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* header */
font-family: Albert Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 1.56rem;
}

.meeting-scheduled{
    color: rgba(248, 248, 248, 0.50);
font-feature-settings: 'clig' off, 'liga' off;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.no-meeting-info{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 39.6875rem;
    background-color: rgba(10, 14, 23, 0.60);
    color: rgba(248, 248, 248, 0.50);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
    
}
.no-meeting-footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

.change-button{
    width: 2.5rem;
height: 2.5rem;
padding-top: 0.3125rem;
background-color: #232CFF;
border-radius: 0.375rem;
text-align:center;
}

.search{
    height: 1rem;
    width: 12.5rem;
    padding-left: 1.5rem;
    /* padding-bottom: 0.5rem; */
}

.searchicon{
    position: relative;
    padding-top: 1rem;
}

.iconSearch{
    position: absolute;
    left: 0;
}