.root {
    background-color: #0A1121;
    // border-color: white;
    // border-style: solid;
    display: flex;
    flex-direction: column;
    height: 50%;
    // min-height: 300px;
    min-height: 18.75rem;
    // min-width: 510px;
    // max-width: 520px;
    width: 100%;
    // background-image: url('./../../assets/defaultHoldingImage.svg');
    background-image: url('./../../assets/relateLogo.png');
    background-size: 30%; 
    // background-image: url('./../../assets/forum360_logo130.svg');
    background-repeat: no-repeat;
    // background-size: contain;
    background-position: center;

    border: 1px solid rgba(248, 248, 248, 0.40);
    border-radius: 0.625rem;

    @media screen and (max-width: 400px) {
        //do Smth
        // min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        //do Smth
    }
}

.rootCall {
    // background-image: url('./../../assets/user.svg');
    // background-repeat: no-repeat;
    // background-size: auto;
    // background-position: center;
    height: 19.813rem;
    min-height: 19.813rem;
    // min-width: 510px;
    // max-width: 520px;
    width: 100%;

    @media screen and (max-width: 400px) {
        //do 
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        //do Smth
    }
}

.screen {
    height: 3.125rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(10, 14, 23, 0.60);
    padding: 0.75rem 1rem;
    background-image: './activescreen.svg';
}

.activescreen {
    color: #F8F8F8;
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
}

.onair {
    color: #D50D55;
    text-align: center;
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.hostname {
    color: rgba(248, 248, 248, 0.60);
    text-align: right;
    font-family: Albert Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
}

.vidyoContainer {
    height: 100%;
    width: 100%;
}

#vidyoContainer video {
    width: 100%;
}

#vidyoContainer .video-display-name-wrapper {
    display: none;
}

.activeScreenHeaderContainer {
    position: absolute;
    top: 0;
    display: flex;
    z-index: 2;
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(10px);
    height: 10rem;
}

.activeQuestionContainer {
    width: 100%;
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(10px);
    position: absolute;
    z-index: 2;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expand {
    max-height: 40rem !important;
    overflow: unset !important;
}

.activeQuestionQuery {
    // max-width: 21.75rem;
    width: 40%;
    padding: 1rem;

    .activeQuestionAsker {
        color: var(--ffffff, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3125rem;
        /* 150% */
    }

    .activeQuestionQues {
        color: #D9D9D9;
        text-overflow: ellipsis;
        whitespace: nowrap;
        font-family: Albert Sans;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
        max-height: 1.75rem;
        overflow: hidden;
    }

}

.chevronUp{
    transform: rotate(180deg);
}