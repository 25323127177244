@import '../../common/styles/fonts.scss';
.badge {
    @include font-12-bold-badge;
    // padding: 0.375rem 0.75rem;
    padding: 0.43rem 0.86rem;
    // width: 6.25rem;
    // width: 7.9rem;
    border-radius: 1.63544rem;
    text-align: center;
}
.inProgress {
    background: #D50D55;
    color: var(--ffffff, #FFF);
}
.startingSoon {
    background: var(--foregrounds-foregrounds-regular, #FFF);
    color: var(--foregrounds-forerounds-prrimary, #232CFF);
}
.upcoming {
    background: var(--foregrounds-forerounds-prrimary, #232CFF);
    color: var(--ffffff, #FFF);
}
.happeningNow {
    // width: 7.9rem;
    background: #FFF;
    color: #D50D55;
}