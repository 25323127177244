// .script{
//     display: flex;
//     justify-content: space-between;
// }
.cryption{
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(5px);
    height: fit-content;
    padding: 5px 1rem 5px 1rem;
    &-heading {
        justify-content: space-between;
        display: flex;
    }
}
