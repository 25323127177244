.login {
    &_container {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        max-width: 20rem;

        &Mid {
            // @extend .login_container;
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: .69rem;
            // justify-content: center;
            // justify-content: unset;
            // margin: unset;
        }
    }

    &_logo {
        margin-bottom: 3.25rem;
        max-width: 14rem;
    }

    &_heading {
        color: var(--ffffff, #FFF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        // margin-bottom: 2.38rem;
        margin-bottom: 1.9rem;
    }

    &_subHeading {
        color: var(--ffffff, #FFF);
        text-align: justify;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &_resendCodeButton {
        color: #7CC6FA;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }

    &_microsoft {
        margin-bottom: 1.29rem;
    }

    &_or {
        text-align: center;
        color: var(--ffffff, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1.19rem;
    }

    &_username {
        margin-bottom: 1.13rem;
        caret-color: #FFF;
    }

    &_password {
        margin-bottom: 1.81rem;
        caret-color: #FFF;
    }

    &_mfa {
        margin-bottom: 1.81rem;
        &_subHeading {
            margin-top: 1.62rem;
            margin-bottom: 1.81rem;
        }
    }

    &_username,
    &_password,
    &_mfa {
        color: var(--ffffff, #FFF);
        // font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        // line-height: normal;
    }

    &_copyright {
        margin-bottom: 1.81rem;
    }

    &_error {
        color: var(--red-primary, #D50D55);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        // margin-bottom: 1.69rem;
        margin-top: -0.5rem;
        margin-bottom: 1.56rem;
    }
    &_success {
        color: var(--green-primary, green);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        // margin-bottom: 1.69rem;
        margin-top: -0.5rem;
        margin-bottom: 1.56rem;
    }

    &_select_org {
        -webkit-appearance: auto;
        width: 100%;
        background:transparent;
        border:none;
        border-bottom: 2px solid rgba(245, 245, 250, 0.40);
        color: white;
        outline: none;
        option {
            background: #3D4553;
            color: white;
        }
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.logo{
    display: flex;

}

.powered{
    color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;

/* caption */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 1rem;
padding-top: 1rem;
padding-right: 1rem;
}

.star{
    height: 10px;
    width: 10px;
    transform: translate(-4px,6px);
}

.forum{
    color: #F8F8F8;
font-family: Questrial;
font-size: 1.00694rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.02013rem;
transform: translate(-15px,15px);
}