@import '../../common/styles/fonts.scss';

#bottom{
    background:rgba(10, 14, 23, 0.60);
    width:100%;
    // height: 5.375rem;
    /* padding-left: 7rem; */
    display:flex;
    justify-content:space-between;
    padding-right:2rem;
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgba(248, 248, 248, 0.40);
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    backdrop-filter: blur(2px);
}

span{
    
    color: white;
}

.tasks-option{
    padding-top: 1.5rem;
    text-align:center;
    width: 10%;
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
@include font-12-semibold-navigation;
position: relative;
}
.tasks-option-tool{
    cursor: pointer;
    padding-top: 1.5rem;
    padding-bottom: 0.2rem;
    text-align:center;
    /* width: 10%; */
    font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
// padding-right:29px;
// width: 10rem;
padding-left: 1.75rem;
padding-right: 1.75rem;
position: relative;
cursor:pointer;
}

.options{
    // margin-left: 2px;
    padding-left: 0.2rem;
}

.meet-options{
    display:flex;
}
.control-options{
    display:flex;
}

.options-tools{
    display: flex;
    // justify-content:space-between;
    padding-left: 2%;
    // width: 25%;
}

.tools{
    padding-top: 0.69rem;
    padding-bottom: 0.2rem;
    padding-left: 1.75rem;
padding-right: 1.75rem;
    text-align:center;
    /* width: 10%; */
    font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
cursor:pointer;
    @media only screen and (max-width: 900px) {
        padding-top: 0;
    }
}

.start-meeting-grp{
    // padding-top: 24px;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
//     background:  #232CFF;
//     width: 170px;
//     height: 55px;
//     text-align: center;
// /* headline */
//     font-family: Albert Sans;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
//     border: 2px #232CFF;
//     border-radius: 10px;
//     color: white;
    // min-width: 16rem;
    width: fit-content;
    position: relative;
}

.access-icon{
    margin-bottom:4px;
}

.settings{
    margin-bottom: 4px;
}

.not-started{
    width:20%;
    display: flex;
    justify-content:space-between;
}

.disabled {
    // background: black;
    opacity: 0.25;
}