.eventmessaging{
    color: #F8F8F8;

    /* header */
    font-family: Albert Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.messagingtab{
    // position: relative;
    padding-top: 1.37rem;
}

.label{
    color: #F8F8F8;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

input{
    border:none;
    background:transparent;
    width: 100%;
    border-bottom:1px solid rgba(10, 14, 23, 0.60);
}

input:focus{
    border-bottom:1px solid rgba(10, 14, 23, 0.60);
}

.subject{
    color: #F8F8F8;
    /* subheading */
    font-family: Albert Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 2.5rem;
}

.quickmessageheading{
    width: 100%;
    margin-top: 1.87rem;
    display: flex;
    justify-content: space-between;
}

.subjectbox{
    padding: 0.2rem 0;
}

.messagebox{
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
padding:1.25rem 0.8rem;
// padding-bottom: 1.25rem;
margin-top: 7px;
color:white;
min-height: 7.5rem;
}

 .messagebox:focus {
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    padding-top:1.25rem;
    padding-bottom: 1.25rem;
}
.quickMessage{
    color: rgba(248, 248, 248, 0.60);
    /* subheading */
    font-family: Albert Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

textarea{
    width: 100%;
    border:none;
    outline: none;
    color:white;
    resize: none;
}

.sendMessage:disabled{
    background-color: transparent;
}
.sendMessage{
    width: 100%;
    background-color:  #232CFF;
    border-radius: 0.625rem;
    color: #F8F8F8;
text-align: center;
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
height: 2.5rem;


}

.messagebutton{
    display: flex;
    position: absolute;
    bottom: 0;
    width: 91%;
    padding-bottom: 25px;
}
.messagebuttonTeams {
    display: flex;
    margin-top: 10px;
    // position: absolute;
    // bottom: 0;
    // width: 91%;
    // padding-bottom: 25px;
}


.messagedropdown{
    position:absolute;
    right:5px;
    width:153px;
    cursor:pointer;
}

.messagepop{
    position: absolute;
   
    width: 85%;
    background: black;
    z-index: 2;
    top: 250px;
    right: 12px;
    background: rgb(10, 14, 23);
    /* padding-right: 14px; */
    margin-right: 19px;
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 1);


}

.poptext{
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.popButtons{
    display: flex;
    justify-content: center;
}

.no{
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: 5.5rem;
height: 2.1875rem;
padding: 0.5rem 0rem;
justify-content: center;
align-items: center;
flex-shrink: 0;

border-radius: 0.625rem;
background: rgba(255, 255, 255, 0.20);
}

.yes{
    color: var(--ffffff, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* headline */
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 5.5rem;
    height: 2.1875rem;
    padding: 0.5rem 0rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 2px solid #232CFF;
    border-radius: 0.625rem;
background: #232CFF;
}