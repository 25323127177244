.research{
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(5px);
    height: fit-content;
    padding: 0.5rem 1rem 1.5rem 1.2rem;
    margin-left: 5px;
    margin-top: 6px;
}

.research-head{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.3125rem;
margin-bottom: 1rem;
margin-top: 0.5rem;
}

.link-button{
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
margin: 2px;
color: white;
border:none;
padding: 4px;
word-break: break-all;
}

.cross{
    width: 1.56rem;
    height: 1.56rem;
    padding-bottom: 3px;
}

.add-link{
margin-top: 1rem;
border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.90);
backdrop-filter: blur(5px);
height: 3.25rem;
padding: 1rem;
padding-bottom: 1rem;
display: flex;
}

.plus-link{
    margin-bottom:1rem;
    width:1.25rem;
    height: 1.25rem;
}

.research-head{
    padding-left: 0.3rem;
    border-bottom: none;
}

.research-head:focus{
    border-bottom: none;
}