.carbon{
    display:flex;
    justify-content: space-between;
    color: white;
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.pinImage{
    height: 1.5rem;
}

.card{
    padding-left:0.88rem;
    padding-right: 0.69rem;
    padding-top:0.66rem;
    padding-bottom: 0.66rem;
    background: rgba(10, 14, 23, 0.60);
    border-radius: 0.625rem;
}

.description{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;

/* caption */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top:0.3rem;
padding-bottom: 0.3rem;
}


.count{
    color: white;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.carboncount{
    color: #2DF09E;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.upcoming{
    font-weight: bold;
    color: blue;
}
.carbonHover{
    position: absolute;
    width: 196%;
    z-index: 10;
    transform:translateY(-30px);
    opacity: 1;
transition: opacity 1s;
//    transform: translateX(-50%);
}

.triangleDown{
    width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #555;
    transform: translateY(-30px);
    // transform: translateX(100px);
    margin-left: 40px;
    
}

.textHover{
    background: #3D4553;
    color: white;
    
    transform: translateY(-30px);
    border-radius: 0.625rem;
    border: 1px solid #FFF;
    padding: 0.8125rem 0.4375rem 0.8125rem 0.875rem;
}

.followUpHover{
    position: absolute;
    width: 200%;
    z-index: 10;
    // opacity: 1;
    // transition: opacity 1s;
    right: 0%;
    transform:translateY(-30px);
}

.triangleFollowDown{
    width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #555;
    transform: translateY(-30px);
    // transform: translateX(100px);
    margin-left: 80%;
}

.ratingHover{
    position: absolute;
    width: 200%;
    z-index: 10;
    // opacity: 1;
    // transition: opacity 1s;
    right: 0%;
    transform:translateY(-20px);
}

.triangleRatingDown{
    width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #555;
    transform: translateY(-30px);
    // transform: translateX(100px);
    margin-left: 80%;
}
.hide {
    display: none;
  }

  .questions{
    display:none;
  }
.hidefollow{
    display: none;
} 
.ratings{
    display: none;
} 
  .myDIV:hover {.hide {
    display: block;
    color: red;
  }
  .questions{
    display:block;
  }
  .hidefollow{
    display:block;
  }
  .ratings{
    display: block;
  }
}