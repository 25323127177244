.feedbacktabs{
    width: 50%;
    
}

.feedbackcontainer {
    margin-top: 5px;
    display: flex;
    text-align: center;

  }

  .feedbackbutton {
    width: 50%;
    margin-left: 0.35rem;
    margin-right: 0.35rem;
    // height: 52px;
    align-items: center;
    background-color: transparent;
    font-size: 1rem;
    border-radius: 0px;
    padding: 0.9rem;
    align-content: center;
    border: none;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border-bottom: solid #ffffff;
    border-bottom-width: 2px;
    color: #F8F8F8;
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: 1.3125rem;

  }
  
  .feedback{
    width: 100%;
    display: flex;
    padding-right: 0.75rem;

  }

  .questions{
    width: 50%;
    background: none;
    border:none;

  }

  .comments{
    width: 50%;
    background: none;
    border:none;

  }

  .buttons{
    width:100%;
    background: none;
    
  }

  .active, .buttons:hover {
    border:none;
    border-bottom: 2px #f8f8f8;
    color: white;

  }
  .messagecount{
    background-color: #D50D55;
    padding: 1px;
    border-radius: 9px;
    // margin-top: 1rem;
    border-radius: 1.875rem;
    // height: 1.25rem;
    padding: 0 0.75rem;
    font-size: 0.75rem;

  }
  .count {
    color: #ffffff;
    font-weight: 550;
    font-size: 0.875rem;

  }
  .h3 {
    color: azure;
    font-size: 0.875rem;
    font-weight: 650;
    // margin-top: 20px;
    // margin-left: 5px;
    border-bottom: #F8F8F8;

  }

  .h4{
    color: rgba(240, 255, 255, 0.568);
    font-size: 0.875rem;
    font-weight: 650;
    // margin-top: 20px;
    margin-left: 5px;
    border:none;

  }
  .qna{
    padding-top:3px;
    overflow-y: scroll;
    max-height: 89%;

    &::-webkit-scrollbar{
      width:0px;

    }
  }


  .feedbacktab{
    color: #F8F8F8;
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: transparent;
    border: none;

  }


  .selectedTab{
    color: #F8F8F8;
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
background: transparent;
border: none;

  }

  .notSelectedTab{
    color: rgba(248, 248, 248, 0.60);
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
background: transparent;
border: none;

  }

  .tabs{
    display: flex;

    justify-content: space-between;
    margin-top: 1rem;

  }

  .addedquestion{
    color: rgba(248, 248, 248, 0.60);
    font-family: Albert Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
  }

  .qnaless{
    max-height: 200px;
    overflow-y:hidden;
  }

  .mainCont{
      position: relative;
  
  }

  .viewLess{
    background:transparent;
    border: none;
    color: rgba(162,162,175);
    font-weight: 550;
    align-items: center;
    margin-left: 24%;
    // margin-top: 1.5%;
  }
  .viewLess:hover{
    color: white;

  }


  .view{
    padding-top: 3px;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    background: linear-gradient(transparent,rgba(51,56,74));
    // height: 110px
  }
  .viewType{
    background:transparent;
    border: none;
    color: rgba(162,162,175);
    // margin-top: 22%;
    font-weight: 550;
    align-items: center;
    // margin-left: 42%;
  }
  .viewType:hover{
    color: white;

  }

  .questiontab{
    height: 90%;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width:0px;

    }
  }

  .comments{
    // position: absolute;
    bottom: 0;
    // padding-top: 20px;
    width: 100%;
    padding-right: 45px;

  }

  .answered{
    margin-left: 4px;

  }

  .questionsshow{
    margin-left: 10px ;

  }