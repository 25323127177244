.feedback-heading{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* header */
font-family: Albert Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 6px;
}
.points{
    color: #F8F8F8;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Albert Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;
    padding-bottom: 2px;
    padding-left: 4px;
    min-width: 60px;
}
input {
    border: none;
    background: transparent;
    width: 100%;
    // border-bottom: 1px solid rgba(10, 14, 23, 0.6);
    border-bottom: 1px solid rgba(248, 248, 248, 0.60);
}
.resource{
    width: 100%;
}

.customerexperience{

    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
backdrop-filter: blur(17.5px);

color: white;
}

.sessionnotes{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;
/* body */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 18px;
}

.customer{
    padding-left: 4px;
}

.formdata{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 100%;
}

.like{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.points{
    margin-top: 5px;
}


.meeting-details{
    position: relative;
    z-index: 1000;
}

.css-1666bipr-Input{
    color: white !important;
}

