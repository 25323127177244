@import "../../common/styles/colors.scss";
@import "../../common/styles/fonts.scss";

// .btnOmni {
//   width: 100%;
//   padding: 13px 0 !important;
//   background-color: $dupage-dark-blue !important;
//   border-color: $dupage-dark-blue !important;
//   border-radius: 10px !important;
//   font-family: Inter;
//   font-style: normal;
//   font-weight: bold !important;
//   font-size: 0.875rem !important;
//   line-height: 1.5rem !important;

//   &:disabled {
//     @extend .btnOmni;
//     background-color: $medium-gray !important;
//     border-color: $medium-gray !important;
//     opacity: 1 !important;
//   }

//   &:focus {
//     box-shadow: none !important;
//   }
// }
// .btnWhite {
//   width: 100%;
//   padding: 13px 0 !important;
//   background-color: $white !important;
//   border-color: $dupage-light-blue !important;
//   border-radius: 10px !important;
//   font-family: Inter;
//   font-style: normal;
//   font-weight: bold !important;
//   font-size: 0.875rem !important;
//   line-height: 1.5rem !important;
//   color: $dupage-dark-blue !important;

//   &:disabled {
//     @extend .btnWhite;
//     background-color: $medium-gray !important;
//     border-color: $medium-gray !important;
//     opacity: 1 !important;
//   }

//   &:focus {
//     box-shadow: none !important;
//   }
// }

// basic
// .btn {
//     // padding: 1.25rem 2.19rem;
//     color: #F8F8F8;
//     text-align: center;
//     /* headline */
//     font-size: 1rem;
//     font-family: Albert Sans;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
// }
.btnWrapper {
    border-radius: 0.625rem;
    border: none;
    padding: .175rem;
    background: linear-gradient(189.69deg,rgba(255, 255, 255, 0.69) -1.26%,rgba(35, 44, 255, 0.69) 100%);
    // width: 100%;
    &:hover{
        padding: 3px;
    }
    width: max-content;
    // margin: auto;
}
.basic {
    // @extend .btn;


    // border-image: linear-gradient(189.68deg,(255, 255, 255, 0.4), rgba(35, 44, 255, 0.4));
//     background: linear-gradient(0deg, #232CFF, #232CFF),
// linear-gradient(0deg, #232CFF, #232CFF),
// linear-gradient(189.68deg, rgba(255, 255, 255, 0.4) -1.26%, rgba(35, 44, 255, 0.4) 100%);

    // ,linear-gradient(#232CFF, #232CFF);


    // border-image: 
    // linear-gradient(
    //     189.68deg,
        //  rgba(255, 255, 255, 0.4) -1.26%,
        //   rgba(35, 44, 255, 0.4) 100%
    //       ),
    //       linear-gradient(0deg, #232CFF, #232CFF);
    // border-image: linear-gradient(#f6b73c, #4d9f0c) 30;
    
    // display: inline-flex;
    padding: 1.25rem 2.1875rem;
    // justify-content: center;
    // align-items: center;

    color: var(--foregrounds-foregrounds-regular, #FFF);
    text-align: center;
    /* headline */
    font-size: 1rem;
    font-family: 'Albert Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--foregrounds-forerounds-prrimary, #232CFF);

    border-radius: 0.625rem;
    border: none;
    // border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1;
    // border: 2px solid #232CFF;
    // border-image-source: linear-gradient(to bottom, #ff0000, #00ff00, #0000ff);

    // border-image: linear-gradient(259deg, rgba(255, 255, 255, 0.40) 0%, rgba(34.71, 43.52, 255, 0.40) 100%);
//     background: linear-gradient(0deg, #232CFF, #232CFF),
// linear-gradient(0deg, #232CFF, #232CFF),
// linear-gradient(189.68deg, rgba(255, 255, 255, 0.4) -1.26%, rgba(35, 44, 255, 0.4) 100%);
    // &:focus{
    //     background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%);
    // }
    // &:hover {
    //     padding: 1.2rem 2.15rem;
    // }
}
.basicWrapper{
    @extend .btnWrapper;
}
.modalWrapper{
    @extend .btnWrapper;
    width: 100%;
}
// modal
.modal {
    padding-top: .45rem;
    padding-bottom: .45rem;

    
    // width: 100%;
    // margin-left: 10px;
    // margin-right: 10px;


    // display: flex;
    // height: 1.5rem;
    // padding: 0.3125rem 3.8125rem;
    // justify-content: center;
    // align-items: center;


    border-radius: 0.35113rem;
    background: #232CFF;


    color: #F8F8F8;
    text-align: center;
    font-size: 0.75rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    // .Wrapper {
    //     // linear gradient here ?
    // }
}

.navigationWrapper {
    // @extend .btnWrapper;
    // background: #747576;
    background-color: transparent;
}
.navigation {
    // background: ;
    background-color: transparent;
    // width: 40px;
    // height: 40px;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 0.625rem;
    border: 1px solid rgba(108, 132, 189, 0.4);
}

.alert{
    padding: 1.25rem 2.1875rem;
    // justify-content: center;
    // align-items: center;

    color: var(--foregrounds-foregrounds-regular, #FFF);
    text-align: center;
    /* headline */
    font-size: 1rem;
    font-family: 'Albert Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--foregrounds-forerounds-prrimary, #FF3346);

    border-radius: 0.625rem;
    border: none;
}
.alertWrapper {

    @extend .btnWrapper;
    background: linear-gradient(189.69deg,rgba(255, 255, 255) -1.26%,rgba(213, 13, 85) 100%);
    // border: 2px solid;
    // border-image-source: linear-gradient(189.68deg, rgba(255, 255, 255, 0.6) -1.26%, rgba(213, 13, 85, 0.6) 100%),linear-gradient(0deg, #D50D55, #D50D55);


}