.feedback-heading{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* header */
font-family: Albert Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 16px;
}

.resource{
    width: 100%;
}

.customerexperience{

    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
backdrop-filter: blur(17.5px);

color: white;
padding: 1rem 1rem 1.25rem 1rem;
}
.customer-exp-heading{
    padding-left: 0.7rem;
}

.customer-sessionnotes{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;
/* body */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.formdata{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 100%;
font-size: 1rem;
}

.custom-form{
    margin-top:0.5rem;
    padding-bottom: 0.5rem;
}
.like{
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding-top: 20px;
}

.attendee{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.city{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 10px;
}

.userlist{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.form-data{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.labels{
    padding-top: 1.31rem;
    padding-bottom: 0.5rem;
}

// .custom-form{
//     background-image: url('./tick.png');
//     background-color: transparent;
//     width:20px;
//     height: 20px;
// }

.custom-input{
    border-bottom: 0.125rem solid rgba(248, 248, 248, 0.6);
    padding-bottom: 0.9rem;
    padding-right: 2.5rem;
}