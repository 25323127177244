.questiontabs{
    padding-top:4px;
}

.comments{
    width: 100%;
    background: rgba(10, 14, 23, 0.90);
    border: 2px solid black;
}
.load{
    bottom: 30px;
    position: absolute;
    /* padding-right: 43px; */
    width: 90%;
}
.loadMore{
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
      }
}
.loadLess{
    overflow-y:hidden;
}