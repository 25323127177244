/* @import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@400;700&display=swap'); */
@import '../../common/styles/fonts.scss';
.pagination_btn{
    width: 100%;
    color: white;
    /* background: rgba(248, 248, 248, 0.15); */
    // margin-top: 1.25rem;
    border-radius: 0.625rem;
    border: 1.15px solid rgba(108, 132, 189, 0.4);
    overflow: hidden;
}

.main_events_table{
  width: 100%;
  background: #111B40 ;
  tbody {
    background: #1A1F2D;
  }
}
.search_input{
  /* max-width: 25rem; */
  min-height: 2rem;
  /* width: 100%; */
  font-size: 0.875rem;
  padding-left: 22px;
  /* background: url('./search.svg') no-repeat; */
  border-bottom: 2px solid rgba(245, 245, 250, 0.40);
}
.search_input:focus{
  border-bottom: 2px solid rgba(245, 245, 250, 0.40); 
}
.main_heading{
  padding: 1.25rem;
  display:flex;
  width: 100%;
  text-align:left;
  color: white;
  background:rgba(248, 248, 248, 0.15);
  padding-top:1.5625rem;
  // padding-bottom: 1.8125rem;
  padding-bottom: 1.5rem;
  justify-content:space-between;
}

.page-no{
  background: transparent;
  border:none;
  color: white;
  height: 2.5rem;
  width: 2.5rem;
  /* margin-top:1rem; */
}

.page-no-prev-next {
  background: transparent;
  border:none;
  color: white;
}
.page-group{
  display:flex;
}

.page-control{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // padding-top: 0.25rem;
  // height: 3.75rem;
  // padding-bottom: 0.625rem;
  background: rgba(248, 248, 248, 0.15);
  // padding-top: 0px;
}

.paging{
  display:flex;
}

.selected-page{
  border-radius: 0.375rem;
  background: #232CFF;
  /* margin-top:1.25rem;
  margin-bottom: 1.25rem; */
}

.table-fonts{
  color: #F8F8F8;
/* header */
font-size: 0.875rem;
font-family: Albert Sans;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.table-header {
  @include font-18-bold-header;
}

.description{
color: rgba(248, 248, 248, 0.50);
/* subheading */
font-size: 0.875rem;
font-family: Albert Sans;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top:0;
margin-bottom: 0;

@include font-14-semibold-subheading;
}

.type{
  color: #F8F8F8;
text-align: center;
/* body */
font-size: 0.875rem;
font-family: Albert Sans;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.Events-row{
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  /* padding-bottom: 0.9375rem; */
  /* display: inline-flex; */
  /* justify-content: center; */
}
.Events-row-new{
  /* padding-top: 1.25rem; */
  /* text-align:left; */
}
.main_events_table tr{
  padding: 1.25rem;
}

input{
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 0.125rem solid rgba(248, 248, 248, 0.60);
}
input:focus
{   
    background-color: transparent;
    outline:none;
   border-bottom: 2px solid rgba(245, 245, 250, 0.40);
    color: white;
}

.img-table{
  padding-left: 1.38rem;
  width: 10%;
}

.meeting-names{
  /* padding-top: 1rem; */
  padding-left: 3.38rem;
  width: 25%;
}

.status{
  padding-right: 2.88rem;
  width: 5%;
}

.product{
  padding-left:4.38rem;
  padding-right: 4.31rem;
  width: 15%;
}

.date-time{
  /* padding-top: 1rem; */
  padding-left: 1rem;
  width: 20%;
}

.page-control{
  padding-right: 11px;
  padding-left: 11px;
}

/* .badge{
  padding-right: 1.37rem;
} */

.status-badge{
  /* padding-top: 1rem; */
  padding-right: 1.37rem;
  width: 20%;
}

.icon {
  max-height: 3rem;
  /* &-search {
    max-height: 1rem;
  } */
}

.icon-search {
  position: absolute;
  top: 40%;
  left: .7rem;
  transform: translate(-50%, -50%);
}

.search-container {
  position: relative;
  width: 20rem
}

.no-meeting{
  height: 23.75rem;
  width: 100%;
  
}

.scheduled{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(248, 248, 248, 0.50);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
  // margin-top:25%;
}
