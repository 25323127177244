.carbontime{
    display:flex;
    width: 100%;
    padding-top: 1rem;
    position:relative;
}

.carbon{
    width: 50%;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
}

.sentiment{
    padding-top: 0.75rem;
}

.activeattendees{
    padding-top:1rem;
}

.viewerdetails{
    margin-top: 1rem;
}

.analytics{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* header */
font-family: Albert Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 10px;
}

.participants{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;

/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.count{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.showAnalytics{
    &::-webkit-scrollbar{
        width:0px;
        display: none;
      }
    overflow-y:scroll;
}

.fufil:hover + .show{
    
        display: block;
        color: red;
      
}

.show{
    display: none;
}

.carbonHover{
    position: absolute;
    width: 180%;
    z-index: 1000;
    transform:translateY(-30px);
    opacity: 1;
transition: opacity 1s;
//    transform: translateX(-50%);
}

.triangleDown{
    width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #555;
    transform: translateY(-30px);
    // transform: translateX(100px);
    margin-left: 40px;
    
}

.textHover{
    background: #3D4553;
    color: white;
    
    transform: translateY(-30px);
    border-radius: 0.625rem;
    border: 1px solid #FFF;
    padding: 0.8125rem 0.4375rem 0.8125rem 0.875rem;
}

.fulfilmentHover{
    position: absolute;
    width: 180%;
    z-index: 1000;
    right: 100px;
}

.triangleFulfilmentDown{
    width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #555;
    transform: translateY(-30px);
    // transform: translateX(100px);
    margin-left: 200px;
    
}

.Hover{
    position: absolute;
    width: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 1s;
    bottom:30px;
    
}