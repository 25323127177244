@import '../../common/styles/fonts.scss';

.tooltip {
    border-radius: 0.625rem;
    color: #FFF;
    width: fit-content;
    position: absolute;
    z-index: 5;
}
.field {
    width: 100%;
    cursor: pointer;
}
.footer {
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(2px);
    @include font-14-regular-body;
    // border: 1px solid rgba(108, 132, 189, 0.4);
    border: 1px solid #6C717E;
    padding: 0.75rem;

    .section {
        display: flex;
        // padding: 0.75rem;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 0.75rem;
    }
    .section:not(:first-child) {
        padding-top: 0.75rem;
    }
    .section:not(:last-child) {
        padding-bottom: 0.75rem;
        border-bottom: 1px solid #6C717E;;
    }

    // background: linear-gradient(0deg, rgba(10, 14, 23, 0.6), rgba(10, 14, 23, 0.6)),
    // linear-gradient(193.44deg, rgba(255, 255, 255, 0.4) 2.36%, rgba(108, 132, 189, 0.4) 100%);

    // border-image-source: 
    // linear-gradient(193.44deg, rgba(255, 255, 255, 0.4) 2.36%, rgba(108, 132, 189, 0.4) 100%);


}

.meetingControls {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);

    display: flex;
    // width: 13.25rem;
    // height: 7.5625rem;
    padding: 0.625rem 0.89381rem 0.70044rem 0.9375rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
}

.sidebar {
    background: #3D4553;

    // typography
    color: #FFFFFF;
    @include font-14-regular-body;
}

.modal {
    background: rgba(10, 14, 23, 0.60);

    // typography
    color: var(--ffffff, #FFF);
    text-align: center;

    @include font-16-bold-headline;
}