// $block: "rederer"

// .#{$block} {
//     height: 500px;
// }
#renderer {
  width: 400px;
  height: 300px;
}

.showSideBar{
    display:block;
    width: 28%;
    height: 100%;
    position: fixed;
    top: 15px;
    right: 10%;
    z-index: 2;
}

vidyo-rendering-container {
    height: 300px;
}

// #vidyoContainer {
//     .video-overlay{
//         display: none;
//     }
//     video {
//         // height: 200px;
//         width: 100%;
//     }
// }

.popup-application {
    display: none;
}

#DivToRenderTo {
    // background-color: red;
    height: 300px;
    margin: 10px;
    width: 300px
}

#remoteCamera, .participantContainer {
    // background-color: blue;
    display: flex;
    flex-direction: row;
    height: 170px;
    margin-top: 2px;
    justify-content: center;
    width: 100%;
}


#remoteCamera div {
    // border-color: black;
    border-style: solid;
    border-width: 1px;
    height: 200px;
    width: 200px;
}

#renderer1{
    height: 500px;
    width: 500px;
}

.waiting-screen {
    display: flex;
    justify-content: center;
}

.active-screen-center{
    position: relative;
    height: 100%;
    margin-left: 8%;
}

.particpants {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.video-display-name-wrapper{
    display: none;
}