.attendees{
    width: 100%;
    padding: 0.75rem 0.69rem 0.81rem 0.87rem;
    background: rgba(10, 14, 23, 0.60);

    border-radius: 0.625rem;

}
.attendeesheading{
    display: flex;
    justify-content: space-between;
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.description{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;
/* caption */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 15rem;
}
.metric{
    display: flex;
    padding-bottom: 0.5rem;
}
.progressrow{
    width: 6rem;
    height: 0.1rem;
    .progress, .progress-stacked {
        --bs-progress-height: 0.5rem !important;
    }
}
.progressdes{
    display: flex;
    justify-content: space-between;
}
.change{
    padding-right:0.25rem;
}