.carbon{
    padding: 1rem 1rem 1.25rem 1.25rem;
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(5px);
}

.save{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carbon-head{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* header */
font-family: Albert Sans;
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 0.3125remp;
}

.cities-head{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;
/* body */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 90%;
padding-top: 0.25rem;
}

.count{
    color: #2DF09E;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 0.56rem;
padding-bottom: 0.94rem;
}

.details{
    display: flex;
}

.userlist{
    width: 100%;
    margin-top: .35rem;
    display: flex;
    flex-direction: column;
}
.user-info{
    display: flex;
    // width: 100vw;
    justify-content: space-between;
    padding-top: 0.7rem;
    align-items: center;
    // display: inline-block;
}

.user{
    display: flex;
    align-items: center;
    // gap: 10px;
    width: 100%;
    justify-content: space-between;
}

.user-left{
    display: flex;
    align-items: center;
}

.user-right{
    width: 55%;
    // display: grid;
    // width: 100%;
    // grid-template-columns: repeat(2,1fr);
    // gap: 100%;
    // gap: 7vw;
}

// .user-right > * {
//     width: 100%;
// }

.user-location{
    display: flex;
    gap: 0.9rem;
    width: 100%;
    // max-width: 80px;
    justify-content: end;
}

.avatar{
    width: 1.75rem;
    height: 1.75rem;
}

.username{
    // padding-top: 3px;
    // font-size: 0.75rem;
    display: flex;
    align-items: center;
    max-width: 6rem;
}

// .place{
//     padding-top: 3px;
//     font-size: 1rem;
//     padding-left: 2px;
//     color: rgba(248, 248, 248, 0.60);
// font-feature-settings: 'clig' off, 'liga' off;
// /* body */
// font-family: Albert Sans;
// font-size: 1rem;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// max-width: 90%;
// display: flex;
// }

.location{
    color: #a8a8a8ff;
    // font-size: 10px;
    font-size: 0.7rem;
    width: 100%;
}

.filter-attendee{
    padding-top: 3px;
    padding-bottom: 3px;

}

.filter-city{
    padding-top: 3px ;
    padding-bottom: 3px;
}

.edit{
    // width: 10px;
    width: 1rem;
    cursor: pointer;
}

datalist{
    background-color: red !important;
    color: yellow;
    position: absolute;

    option{
        background-color:red ;
    }
}


.place{
Select{
    z-index: 110000;
    background: black;
}

}

.language{
    color: white;
}

.carbon-head-icons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    // margin-top: -20px;
    // margin-right: -5px;
}

// .user{
//     display: flex;
//     align-items: center;
//     gap: 10px;
// }
 
// .user-left, .user-right{
//     justify-content: start;
// }

// .usr-right{
//     display: flex;
//     // flex-direction: column;
//     gap: 2vw;
// }
 
// .user-location{
//     display: flex;
//     gap: 7px;
// }
 
// .avatar{
//     width: 20px;
//     height: 20px;
// }

// .location{
//     color: #a8a8a8ff;
//     font-size: 10px;
// }

// .edit{
//     width: 10px;
//     cursor: pointer;
// }