.navbar {
    padding-top: 1rem;
    padding-bottom: 0.79rem;
    width: 100%;
    padding-left: 4.38rem;
    padding-right: 4.38rem;
    display: flex;
    align-items: center;

    .logo {
        // width: 6rem;
        height: 3.42538rem;
        margin-left: 2rem;
    }
}

.back {
    // padding: 0.69rem;
    margin-left: 2rem;
}

.nav {
    &_meeting {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 33%;

        &Title {
            color: #F8F8F8;
            text-align: center;
            font-family: Albert Sans;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;
            /* 150% */
        }

        &Desc {
            color: rgba(248, 248, 248, 0.60);
            font-family: Albert Sans;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3125rem;
            /* 150% */
        }
    }
    &_logout {
        &_container {
            margin-left: auto;
        }
        &_userIcon {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: contain;
            border-radius: 50%;
        }
        &_dropdown {

        }
    }
}