.container {
    padding: 1rem;
    background: rgba(10, 14, 23, 0.6);
    border-radius: 0.625rem;
}
.heading {
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;

/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;

padding-bottom: 1rem;
}

.avatar {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: .2rem;
}
.username {
    font-size: 0.9rem;
    color: #F8F8F8;
    display: flex;
    align-items: center;
}

.userLeft {
    display: flex;
}

.subHeading{
    color: #F8F8F8;
    display: none;
}
.pagination-container {
    // style={{display:'flex',justifyContent:'center',position:'relative'}}
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 0.5rem;
}
.pagination {
    // position: relative;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    gap: 0.5rem;
    // justify-content: space-between;
    align-items: center;
    margin: 0;
    // margin-top: 0.6rem;
    // margin-bottom: 0.2rem;

    color: rgba(248, 248, 248, 0.60);

font-family: Lato;
font-size: 0.9rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.page-link {
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: rgba(248, 248, 248, 0.60);
}

.page-item {
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev{
    // margin-right: 5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
}
.prev-link {
    color: rgba(248, 248, 248, 0.60);
    text-decoration: none;
}
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
}
.next-link {
    color: rgba(248, 248, 248, 0.60);
    text-decoration: none;
}

.active {
    border-radius: 6px;
    border: 2px solid #FFF;
    background: var(--Foregrounds-forerounds-prrimary, #232CFF);
}