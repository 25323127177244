$block: "side-bar";

.#{$block}__sidebox{
    // border-radius: 10px;
    // background: rgba(248, 248, 248, 0.15);
    // backdrop-filter: blur(2px);
    // width:100%;
    height:100vh !important;
    overflow: scroll;
    max-height: unset !important;
    // display:inline-block;
    // float:right;
    padding-left:0 !important;
    padding-right:0 !important;
    padding-top:0 !important;
    padding-bottom: 0 !important;
    background: #1f1f1f;
    overflow-x: hidden;

    // display: flex;
    // flex-direction: column;
    // &::-webkit-scrollbar{
    //     width:0px;
    // }
}
// .tab-content{
//     height: unset !important;
// }
.content {
    height: unset !important;
    overflow: unset !important;
}