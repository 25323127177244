.popup {
    &Container{
        display: flex;
        // z-index: 10;
max-width: 24rem;
// height: 59px;
padding: 1rem 1.5rem;
justify-content: center;
align-items: center;
gap: 1rem;

        border-radius: 10px;
        border: 1px solid #FFF;
        background: rgba(10, 14, 23, 0.70);
        backdrop-filter: blur(20px);
    }
    &Text {
        color: #F8F8F8;

font-feature-settings: 'clig' off, 'liga' off;
/* body */
font-family: "Albert Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
}