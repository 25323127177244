.newChanges{
    border-radius: 0.625rem;
    background: rgba(10, 14, 23, 0.60);
    backdrop-filter: blur(5px);
    color: white;
    padding: 1rem;
    margin: 0.1rem 0;
}

.date{
    color: rgba(248, 248, 248, 0.60);
    font-feature-settings: 'clig' off, 'liga' off;
    /* caption */
    font-family: Albert Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding-left:0.5rem;
    padding-top: 0.2rem;
}

.userName{
    color: #F8F8F8;
font-feature-settings: 'clig' off, 'liga' off;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;

}

.comments{
    padding-top:0.2rem;
    color: #c3bebe;
    font-feature-settings: 'clig' off, 'liga' off;
    /* body */
    font-family: Albert Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-word;
}
.load{
    bottom: 30px;
    position: absolute;
    /* padding-right: 43px; */
    width: 90%;
}