.Events-table{
    color: white;
}

/* .App{
    background: linear-gradient(180deg, #0E101C 0%, #111B40 100%);
    height: 100vh;
} */

.Events-table{
    text-align:left;
    width: 50%;
    


}


.form-control {
    border: none;
    background: transparent;
    color:white;
    margin-left:auto;
    margin-right: 0;

  }
  
  .input-group>.form-control:focus {
    border: none !important;
    background:transparent;

    /* box-shadow: inset 0 0 0 1px transparent; */
  }
  tr {

  }
  th{
    border:none;
  }
  tr{
    border-top:none;
    border-bottom: none;
  }
  .heading{
    display:flex;
    width: 50%;
    text-align:left;
    color: white;
    background:rgba(248, 248, 248, 0.15);


  }
  .badge{
    width: 100px;
    height: 26px;
    border-radius: 30px;
    background: #D50D55;
    color: white;
  }