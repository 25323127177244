.setagenda{
    background: rgba(248, 248, 248, 0.15);
    margin: 10% 20% 10% 20%;
    height: 100vh;
    border-radius: 1rem;
}

.agenda{
    padding: 1.25rem;
    display: flex;
    justify-content: center;
    color: white;
    font-size: 2rem;
    
}

.alignment{
    text-align: center;
}


.box{

    font-size: 1rem;
    padding-top: 5rem;
    width: 100%;
}

.submit{
    /* padding-top: 4rem; */
    /* border-radius: 20rem; */
    background: #232CFF;
    /* border: 1px solid white; */
    border-radius: 0.5rem;
    margin-top: 2rem;
    color: white;
    font-size: 1rem;
    width: 6rem;
    height: 3rem;
}

.thanks{
    font-size: 1rem;
    padding-top: 6rem;
    color: rgba(248, 248, 248, 0.60);
}