.question{
    width: 100%;
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
backdrop-filter: blur(5px);
height:fit-content;
padding:1rem;
}

.date{

color: rgba(248, 248, 248, 0.60);
/* caption */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 10px;
}

.ask{
    color: #F8F8F8;
/* body */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.name{
    color: #F8F8F8;
/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.agenda{
    color: rgba(248, 248, 248, 0.60);
text-align: right;
/* navigation */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-right: 5px;
cursor: pointer;
}

.irp{
    color: #F8F8F8;
text-align: right;
/* navigation */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
cursor: pointer;
}

.question-details{
    display:flex;
    justify-content: space-between;
}

