.agendaCard{
    width:100%;
}
.agendacontainer{
  height: 100%;
}

// .agendacontainer {

//     display: grid;
  
//     grid-auto-rows: min-content;
  
//     gap: var(--size-3);
  
//     overflow-y: scroll;
  
//     height: 100% !important;
  
//     overscroll-behavior: contain;
  
//     grid-auto-flow: row;
  
//     margin-top: 15px;
  
//     justify-content: center;
  
//   }



//   Droppable:hover{
//     border: 2px solid #232CFF;
//   }