.sharemeeting{
    
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
backdrop-filter: blur(17.5px);
margin-left: 6px;
color: white;
// padding: 16px 16px 20px 16px;
padding: 1.2rem 1.2rem 2rem 1.2rem;
margin-top: 6px;
}

.share-head{
    font-size: 1.125rem;
}

.team{
    font-size: 1rem;
    padding-top: 10px;
}

.check{
    width: 10px;
}

.team-members{
    padding-left: 0.625rem;
    padding-top: 0.1rem;
}

.details-input{
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.sendHost{
    padding-bottom:'4px';
}
input{
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 0.125rem solid rgba(248, 248, 248, 0.60);
}
input:focus
{   
    background-color: transparent;
    outline:none;
   border-bottom: 2px solid rgba(245, 245, 250, 0.40);
    color: white;
}

.dropdownuser{
    position: absolute;
    right: 0;
}

select{
    width: 100%;
    background:transparent;
    border:none;
    border-bottom: 2px solid rgba(245, 245, 250, 0.40);
    color: white;
    outline: none;
}

input{
  color:white;
}

select.details-input option{
    background: #3D4553;
    color: white;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .share{
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;
/* body */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

  .team{
    padding-top: 5px;
  }

  // .css-13cymwt-control{
  //   background-color: transparent !important;
  // }
  // .css-t3ipsp-control{
  //   background-color: transparent !important; 
  //   box-shadow:none !important;
  // }

  // .css-t3ipsp-control:hover{
  //   border: none !important;
  //   border-bottom: 2px solid rgba(245, 245, 250, 0.40) !important;
  // }

  // .css-1nmdiq5-menu{
  //   background-color: rgba(10, 14, 23) !important;
  //   color:white;
  // }

  // .css-1p3m7a8-multiValue{
  //   background: rgba(10, 14, 23, 0.60) !important;
  //   color: white !important;
  //   color: #F8F8F8 !important;
  // }
  // .css-wsp0cs-MultiValueGeneric{
  //   color: white !important;
  // }

  // .share-details{
  //   input{
  //     color: white !important;
  //   }
  // }

//   .css-13cymwt-control{
//     border:none !important;
//     border-bottom:  1px solid rgba(248, 248, 248, 0.60) !important;

//   }
//   .css-tj5bde-Svg {
//     // display: none !important;
//     fill: currentColor;
//     line-height: 1;
//     stroke: currentColor;
//     stroke-width: 0;
// }

// .css-1u9des2-indicatorSeparator {
//     width: 0px !important;
// }
// .css-1hb7zxy-IndicatorsContainer {
//     display:none !important;
// }


// .share-details{
//   input{
//       color: white !important;
//   }
// }

.change{
  background: rgba(10, 14, 23, 0.60);;
}