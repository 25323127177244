@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@mixin font-22-bold-display {
    /* display */
    font-size: 1.375rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@mixin font-18-bold-header {
    /* header */
    font-size: 1.125rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@mixin font-16-bold-headline {
    /* headline */
    font-size: 1rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@mixin font-14-semibold-subheading {
    /* subheading */
    font-size: 0.875rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@mixin font-14-regular-body {
    /* body */
    font-size: 0.875rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@mixin font-12-regular-caption {
    /* caption */
    font-size: 0.75rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@mixin font-12-bold-badge {
    font-size: 0.75rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@mixin font-12-semibold-navigation {
    /* navigation */
    font-size: 0.75rem;
    font-family: Albert Sans;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}