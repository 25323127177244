.login_subHeading {
    color: var(--ffffff, #FFF);
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

// .login_copyright {
    // position: absolute;
    // margin-top: 1rem;
    // margin-bottom: 1.81rem;
    // bottom: 0;
    // left: 50%;
// }

.footerContainer {
    margin-top: 1rem;
    margin-bottom: 1.81rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.logo{
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
}

.powered{
    color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;

/* caption */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
// padding-left: 1rem;
// padding-top: 1rem;
padding-right: 1rem;
}
