.root{
    background-color: #0A0E17;
    // border-style: solid;
    // border: 1px solid #FFFFFF;
    border: 1px solid rgba(158, 152, 199, 0.4);

    // border-image-source: linear-gradient(193.44deg, rgba(255, 255, 255, 0.4) 2.36%, rgba(108, 132, 189, 0.4) 100%);
    height: 12.5rem;
    width: 21.5rem;

    border-radius: 10px;
    // height: 150px;
    // margin-left: 5px;
    // margin-right: 5px;
    // width: 260px;
    display: flex;
    flex-direction: column;

    // .video-overlay{
    //     display: none !important;
    // }
}
.root--active-participant {
    position: fixed;

      .vid{
        height: 80%;
        width: 97%;
        // height: 27.6rem;
        // width: 77.9rem;
      }
}
// .video-overlay{
//     display: none !important;
// }

.vid {
    border-radius: 0.375rem;
    overflow: hidden;
    background-image: url('./User.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 5.7rem;
    // height: 100px;
    // min-height: 100px;
    // margin: 10px;
    // width: 240px;
    // min-width: 240px;

    // height: 9rem;
    margin: 1.5%;
    // width: 17rem;

    height: 80%;
    width: 97%;

    // video {
    //     width: 100%;
    // }
    // .video-overlay{
    //     display: none !important;
    // }
    // .fecc-control{
    //     display: none;
    // }
}

// .vid > div.video-overlay {
//     display: none;
// }

.details {
    display: flex;
    justify-content: space-between;
    // padding: 5px;
    color: rgba(248, 248, 248, 0.60);
font-feature-settings: 'clig' off, 'liga' off;

/* subheading */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;

padding-left: 1rem;
padding-right:  1rem;
padding-bottom: 1.06rem;
}

.userIconDiv{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.userIcon{
    height: 2.5rem;
    width: 2.5rem;
}

#container {
    display: none;
}
