.sentPop{
    z-index: 2;
    top: 250px;
    right: 12px;
    background: rgb(10, 14, 23);
    /* padding-right: 14px; */
    margin-right: 19px;
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.6);
width: 15rem;
color: white;
   padding: 10px ;
}
.messagepop{

    z-index: 2;
    top: 250px;
    right: 12px;
    background: rgb(10, 14, 23);
    /* padding-right: 14px; */
    margin-right: 19px;
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.6);
width: 15rem;
padding-top: 10px;
padding-bottom: 10px;
}
.poptext{
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.popButtons{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    
}

.no{
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: 5.5rem;
height: 2.1875rem;
padding: 0.5rem 0rem;
justify-content: center;
align-items: center;
flex-shrink: 0;

border-radius: 0.625rem;
background: rgba(255, 255, 255, 0.20);
}

.yes{
    margin-left:12px;
    color: var(--ffffff, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* headline */
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 5.5rem;
    height: 2.1875rem;
    padding: 0.5rem 0rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 2px solid #232CFF;
    border-radius: 0.625rem;
background: #232CFF;
margin-left: 20px;
}

// .activeScreenCenter{
//     width: 100%;
//     display:flex;
//     justify-content: center;
//     width:50%;
//     height: 32rem;
//     border-radius: 0.625rem;
//     background-image: url('./activescreen.svg') no repeat;
//     border: 2px solid white;
// }

// .activeScreenSide{
    
//     width:50%;
//     height: 32rem;
//     border-radius: 0.625rem;
//     background-image: url('./activescreen.svg') no repeat;
//     border: 2px solid white;
// }

.waitingScreen{
    // width: 100%;
    position: relative;
    height: 100%;
    margin-left: 8%;
}

.waitingScreenSide{
    // width: 100%;
    display: flex;
    justify-content: center;
}

.screen{
    height: 3.125rem;
    width:100%;
    display: flex;
    justify-content: space-between;
    background: rgba(10, 14, 23, 0.60);
    padding : 0.75rem 1rem;
}

.activescreen{
    color: #F8F8F8;
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem;
}

.onair{
    color: #D50D55;
text-align: center;
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.hostname{
    color: rgba(248, 248, 248, 0.60);
text-align: right;
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 1.3125rem;
}

.participants{
    width: 100%;
}


.popUpBox{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
}

// .messagepop{
//     position: absolute;
   
//     width: 85%;
//     background: black;
//     z-index: 2;
//     top: 250px;
//     right: 12px;
//     background: rgb(10, 14, 23);
//     /* padding-right: 14px; */
//     margin-right: 19px;
//     border-radius: 0.625rem;
// background: rgba(10, 14, 23, 1);


// }

.poptext{
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.popButtons{
    display: flex;
    justify-content: center;
}

.no{
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
/* headline */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: 5.5rem;
height: 2.1875rem;
padding: 0.5rem 0rem;
justify-content: center;
align-items: center;
flex-shrink: 0;

border-radius: 0.625rem;
background: rgba(255, 255, 255, 0.20);
}

.yes{
    color: var(--ffffff, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* headline */
    font-family: Albert Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 5.5rem;
    height: 2.1875rem;
    padding: 0.5rem 0rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 2px solid #232CFF;
    border-radius: 0.625rem;
background: #232CFF;
}

.showPop{
    width: 100%;
    display: flex;
    justify-content:center;
    position: absolute;
    top: 40%;
}
.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}