.mdContainer {
  border-radius: 10px;
  border: 1px solid grey;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 10vw;
  margin-right: 10vw;
  background: #323442;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  //   align-items: center;
  color: #f8f8f8;
  text-align: left;
  font-size: 1.2rem;
  padding-top: 3.5rem;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 2rem;
  gap: 1.25rem;

  & img {
    max-width: 90%;
  }
  .keyContainer {
    display: flex;
    gap: 2rem;
  }
  .copyButton {
    background: transparent;
  }

  input {
    background: black;
  }
}
