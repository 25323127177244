$block: "side-bar";

.#{$block}__text{
    color: white;
}

.#{$block}__sidebox{
    border-radius: 10px;
background: rgba(248, 248, 248, 0.15);
backdrop-filter: blur(2px);
width:100%;
height:90%;
max-height: 90%;
display:inline-block;
float:right;
padding-left:1.25rem;
padding-right:1.25rem;
padding-top:1.25rem;
padding-bottom: 1.375rem;
display: flex;
flex-direction: column;
&::-webkit-scrollbar{
    width:0px;
  }
}
// .messaging-tab{
//     padding-top: 3px;
// }
.img-tools{
    // padding-bottom: 8px;
    height: 2.5rem;
}
// .#{$block}__sidebox:hover{
//     overflow-y:scroll;
//     margin: 0 auto;
// }

.#{$block}__heading-sidebar{
    width: 100%;
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.#{$block}__relate{
    color: #F8F8F8;
/* display */
font-family: Albert Sans;
font-size: 1.75rem;
font-style: normal;
font-weight: 700;
line-height: normal;
/* padding-top: 5px; */
margin: auto;
/* transform:translateY(3px); */
}

/* .side-tools{
    display:flex;
} */

.side-tools{
    padding-top: 9px;
}
.#{$block}__side-tools{
    display: flex;
    align-items: center;
}
.#{$block}__option-ham{
    // padding: 1rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    border: 1px black;
}
.abc::before{
    content: "<img>";
}

.side-tabs{
    margin-left:5px;
    flex: 1;
    min-height: 0;
    flex-direction: column;
}

.tabs{
    display:flex;
    justify-content: flex-start;
    padding: 0.15rem;
}

.nav-pills{
    padding-right: 0px;
}

.nav-item{
    /* width:25%; */
}

.content{
    padding-left: 0px;
    flex: 1;
    min-height: 0;
    flex-direction: column;
    padding-right: 1.5rem;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.content::-webkit-scrollbar{
    display: none;
}

.nav-item{
    /* width: 30%; */
}

.nav-link{
    // padding: 7.73px 0px;
    width: 100%;
    padding: 0.69rem;
}

// .messaging{
//     padding-left: 5px;
//     padding-bottom: 3px;
// }

.nav-pills{
    color: rgba(248, 248, 248, 0.60);
}

.tabs-color{
    color: rgba(248, 248, 248, 0.60);
    padding-top:2px;
}

.tabs{
    background: rgba(10, 14, 23, 0.90);
    border-radius: 0.375rem;
}

.nav-item{
    // min-width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-pills .nav-link.active{
//  background: #232CFF;
 background: linear-gradient(189.69deg, rgba(255, 255, 255, 0.4) -1.26%, rgba(35, 44, 255, 0.4) 100%);
 color: #F8F8F8 !important;
text-align: center;
/* navigation */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 600;
height: 100%;
padding: 0.15rem;

}

.message{
    flex:1;
}

.feedback{
    flex:1;
}

.analytics{
    flex:1;
}

.resources{
    flex:1;
}

.relate-tools{

}

.tab-content{
    // height: 100%;
    display: flex;
    flex-direction: column;
}

.feedback{
    // overflow-y: scroll;
    &::-webkit-scrollbar{
        width:0px;
      }
}

// .tabs{
//     font-size: 0.75rem;
// }

// .tab-content >.active{
//     overflow-y:scroll;
//     &::-webkit-scrollbar{
//         width:0px;
//       }
// }

.scroll-content{
    overflow-y:scroll;
    &::-webkit-scrollbar{
        width:0px;
      }
}

.nav-pills .nav-link {
    /* border-radius: var(--bs-nav-pills-border-radius); */
    color: rgba(248, 248, 248, 0.60) !important;
    border-radius: 0.375rem;

}

.message-tab{
    padding-top: 4px;
}

.feedback-tab{
    padding-top: 4px;
    // margin-bottom: 4p
}

.analytics-tab{
    padding-top: 4px;
}

.resources-tab{
    padding-top: 4px;
}

.tab-select{
    font-size: 0.75rem;
}

.tools-tab{
    display: flex;
    justify-content: center;
}

.active-tab{
    background:  #232CFF;
    height: 100%;
    display:flex;
    align-items: center;
    border-radius: 0.375rem;
    
}

.inactive-tab{
    background: transparent;
    // padding-top: 1rem;
    display:flex;
    align-items:center;
}

.analyticsscroll{

      display: flex !important;
      flex-direction: column;
      min-height: 0;
}

// .set-message{
//     padding-top: 0px;
// }

// .unset-message{
//     padding-top:4px;
// }

.agendaCount{
    border-radius: 1.875rem;
    background: #D50D55;
    height: 1.25rem;
padding: 0rem 0.5rem;
}

.noscroll{
    display:none !important;
}

.few-to-many-btns {
    width: 95%;
    display: flex;
    // flex-grow: 1;
    gap: .5rem;
    margin: auto;
    margin-top: none;
    margin-bottom: .69rem;
    margin-left: 5px;

    button{
        width: 100%;
    }
    button.blue{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: black;
        color: white;
        border: 1px solid grey;
        border-radius: .5rem;
        font-size: .75rem;
    }
    
    button.red{
        background-color: red;
        color: white;
        font-weight: 600;
        border: 1px solid grey;
        border-radius: .5rem;
        font-size: .75rem;
    }
}
