.config-heading {
    color: azure;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.config-text {
    color: azure;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;

    padding: 5rem;
}