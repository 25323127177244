* {
  font-family: Albert Sans;
}


.App {
  /* text-align: center; */
  height: 100vh;
  /* padding-left: calc((100vw - 1512px) / 2); */
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #0E101C 0%, #111B40 100%);

  /* padding-left: 4.45rem; */
  /* padding-right: 4.45rem; */
  /* padding-top: 2rem; */
  /* background: linear-gradient(180deg, #0E101C 0%, #111B40 100%); */
}
.main-container{
  /* max-width: 1512px; */
  width: 100%;
  /* justify-content: space-between; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background: url('./assets/Frame\ 163.svg');
  height: 100%;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root--active-participant {
  position: fixed;

    .vid{
      height: 80%;
      width: 96%;
    }
}


.login_subHeading {
  color: var(--ffffff, #FFF);
  text-align: justify;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Albert Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login_copyright {
  position: absolute;
  margin-bottom: 1.81rem;
  bottom: 0;
  left: 50%;
}

.logo{
  display: flex;

}

.powered{
  color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;

/* caption */
font-family: Albert Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 1rem;
padding-top: 1rem;
padding-right: 1rem;
}

.video-overlay {
  display: none !important;
}

.red {
  color: red;
}