.commonCard{
    border-radius: 0.625rem;
background: rgba(10, 14, 23, 0.60);
backdrop-filter: blur(5px);
width: 100%;
padding:8px;
margin-top: 8px;
}
.commonCard:hover {

    // background-color: white;
  
    // box-shadow: inset 0 0 0 1px #232cff;
  
  }

.commonCard:hover{
    border: 2px solid #232CFF;
}
.agendainfo{
    display:flex;
    justify-content: space-between;
}

.agendaContent{
    color: #F8F8F8;
/* body */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
display:flex;
padding: 5px;
word-break: break-word;
}

.time{
    color: rgba(248, 248, 248, 0.60);
/* caption */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 5px;
}

.agendaquestion{
    margin-right: 8px;
}


.agendaquestions :hover{
    border: 2px solid #232CFF;
}

.IRP{
    background-color: #232CFF;
    color: #FFF;
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;
/* navigation */
font-family: Albert Sans;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 3px;
padding-top: 3px;
margin-right: 5px;
}

.check{
    display: flex;
    padding-right: 10px;
}

.checkbox{
    height: 1.5rem;
    cursor: pointer;
}