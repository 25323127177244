.main {
    padding: 1rem;
    padding-bottom: 0;
    height: 100%;
    overflow-y: scroll;
    background: #1f1f1f;
}
.main-message::-webkit-scrollbar {
    width: 0px;
}

.main::-webkit-scrollbar {
    width: 0px;
}
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1F1F1F;
}

.error-message {
    font-family: Albert Sans;
    color: #D50D55;
    font-size: 14px;
    margin-top: 0px;
    text-align: center;
}

.login-heading {
    color: azure;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-input {
    width: 250px;
    padding: 10px;
    margin-bottom: 7px;
    border: none;
    border-radius: 5px;
    // background-color: #1F1F1F;
    background: rgb(37, 37, 37);
    color: white;
    text-align: center;
}

.login-button {
    width: 100px;
    padding: 10px;
    background-color: #232cff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.login-button:hover {
    background-color: #1d1dfe;
}

.login-button:active {
    background-color: #1515f2;
}

.login-newButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-newButton {
    width: 100px;
    padding: 10px;
    background-color: #232cff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.login-newContainer > div {
    margin-top: 21px;
}

.login-newHeading {
    color: azure;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
}
.login-subHeading{
    font-size: 12px;
    color: #EEEADE;
    text-align: center;
}