.dashboardroom{
    // background: linear-gradient(180deg, #0E101C 0%, #111B40 100%);
    // padding-bottom: 0.25rem;
    padding-left: 4.38rem;
    padding-right: 4.38rem;
}

.carouselAndTable {
    width: 60.75rem;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
}

.login_subHeading {
    color: var(--ffffff, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* body */
font-family: Albert Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
    
}
.dashboard_footer {
    // position: absolute;
    // margin-bottom: 1.81rem;
    // bottom: 0;
    // left: 50%;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
}

.logo{
    // left: 50%;
    // transform: translateX(-50%);

    & > img {

        // width: 9.758rem;
        // height: 2.736rem;
        // flex-shrink: 0;
        // padding-bottom: 0.3rem;
    }

}

// .powered{
//     color: #FFF;
// font-feature-settings: 'clig' off, 'liga' off;

// /* caption */
// font-family: Albert Sans;
// font-size: 1rem;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// padding-left: 1rem;
// padding-top: 1rem;
// padding-right: 1rem;
// }
