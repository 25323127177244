.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 5;
    &_spinner {
        // border: 16px solid #f3f3f3; /* Light grey */
        // border-top: 16px solid #3498db; /* Blue */
        // position: absolute;
        // top: 50%;
        // left: 50%;
        border: 10px solid #2C2F38;
        border-top: 10px solid #232cff;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 1s linear infinite;
        
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }
}