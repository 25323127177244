.blurBg {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 5;
}


.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.checklist {
    &Container {
        max-width: 27.75rem;
        // height: 36.875rem;
        border-radius: 0.625rem;
        background: rgba(10, 14, 23, 0.60);
        backdrop-filter: blur(2px);
        border: 1px solid rgba(111, 142, 190, 0.7);
        // border-image-source: linear-gradient(193.44deg, rgba(255, 255, 255, 0.4) 2.36%, rgba(108, 132, 189, 0.4) 100%);
        padding: 1.25rem;

    }

    &Item {
        border-radius: 0.625rem;
        background: rgba(10, 14, 23, 0.60);
        backdrop-filter: blur(5px);

        display: flex;
        padding: 1rem;
        align-items: center;
        gap: 1rem;

        &Check {
            display: flex;
            align-items: center;
        }

        &Text {
            color: var(--ffffff, #FFF);
            font-feature-settings: 'clig' off, 'liga' off;

            /* body */
            font-family: Albert Sans;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &Heading {
        color: #F8F8F8;
        font-feature-settings: 'clig' off, 'liga' off;

        /* header */
        font-family: Albert Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    &SubHeading {
        // margin-top: 1rem;
        padding-left: 0.25rem;
        color: var(--ffffff, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;

        /* headline */
        font-family: Albert Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        // margin-top: 0.94rem;
    }

    &SupportText {
        color: var(--ffffff-50, rgba(255, 255, 255, 0.50));
        // text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;

        /* subheading */
        font-family: Albert Sans;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}